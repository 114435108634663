import Blog1 from '../components/Blog/Blog1'
import React from 'react'

function Blog(){
  return(
    <>
    <Blog1 />
    </>
    )
}

export default Blog