import React from 'react'
import "./Vision.css"


function Vision (){
  return(
    <div className="containervision">
      <div className="contain-vision">
        <h1>VISI</h1>
        <hr/>
        <p>Menjadi penyedia solusi logistik terpadu yang terpercaya, terluas, dan terkemuka di Indonesia</p>
      </div>
      <div className="contain-misi">
        <h1>MISI</h1>
        <hr/>
        <ul>
          <li>Memberikan solusi logistik yang efisien dan terintegrasi bagi pelanggan serta mendukung daya saing logistic nasional.</li>
          <li>Memberikan kontribusi laba yang maksimal dan membangun sinergi usaha dengan PT Antar Benua logistic</li>
          <li>Membangun kemitraan usaha dengan mitra kerja strategis yang saling menguntungkan.</li>
          <li>Terus berupaya mengembangkan kompetensi karyawan dan organisasi agar memiliki daya saing nasional.</li>
        </ul>
      </div>
    </div>
    
    )
}

export default Vision