import React from 'react'
import PageService from '../components/Pageservice/Pageservice'
import BlogList from '../components/BlogList/BlogList' 

function Service () {
  return(
    <>
    <PageService />
     <BlogList/>
    </>
    )
}

export default Service