import React, { Component } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Contact.css'
import MoreButton from '../MoreButton/MoreButton'
class MapComponent extends Component {
  componentDidMount() {
    if (!this.map) {
      this.map = L.map('map').setView([629, -0.09], 13); 

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);

      
      L.marker([-6.1324256, 106.7003258]).addTo(this.map)
        .bindPopup('Pt. antar benua logistik').openPopup();
    }
  }

  render() {
    return (
      <div className="containermain-map">
      <div className="rowmain-map">
      <h1>Hubungi Kami</h1>
        <p>Jl. Tanjung Pura No.5
RT.03 / RW.02
Kel. Pegadungan - Kec. Kalideres 
Jakarta Barat 11830</p>
<a href="http://wa.me/6282386837585">
 <MoreButton MoreButton text="HUBUNGI KAMI" type="yellow" /></a>
      </div>
        <div id="map" ></div>
      </div>
    );
  }
}

export default MapComponent;

