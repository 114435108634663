import React, { useState } from "react";
import { Link } from 'react-router-dom'
import "./Header.css"


function Navbar() {
  const [click, setClick]= useState(false);
  const handleClicl =()=> setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const onMouseEnter = () => {
    
  }
  
  return(
    <>
    <nav className="navbar">
      <Link to="/" className='navbar-logo'>
      <img src="LOGO - Antar Benua Logistik-01.png"/>
      </Link>
      <div className='menu-icon' onClick={handleClicl}>
        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li className='nav-item'>
          <Link to="/" className='nav-links' onClick={closeMobileMenu}>
          Home
          </Link>
        </li>
        <li className='nav-item'>
          <Link to="/Service" className='nav-links' onClick={closeMobileMenu}>
          Service 
          </Link>
        </li>
        <li className='nav-item'>
          <Link to="/Contact" className='nav-links' onClick={closeMobileMenu}>
          Contact Us
          </Link>
        </li>
        <li className='nav-item'>
          <Link to="/Blog" className='nav-links' onClick={closeMobileMenu}>
          Blog
          </Link>
        </li>
      </ul>
    </nav>
    
    </>
    )
}



export default Navbar