import React from 'react';
import './Question.css';
import Answer from '../Answer/Answer';


function Question() {
  return <div className="container">
   <Answer />
  </div>
}

export default Question;