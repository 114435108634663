import React from "react";
import "./Services.css";
import Highlight from "../Highlight/Highlight";
import ServiceContent from "./ServiceContent/ServiceContent";
import MoreButton from '../MoreButton/MoreButton'


const Services = () => {
  return (
    <div className="white-container">
      <div className="services-section">
        <div className="heading">
          <Highlight info="Service" />
          <h1 className="display-text">Transportation services</h1>
          <div className="background-class"></div>
        </div>
        <div className="content-service">
          <ServiceContent
            img="./plane-icon.png"
            heading="Air Freight"
            content="Jaringan angkutan udara kami menawarkan akses ke berbagai pilihan layanan untuk kargo udara Anda, termasuk Consol, Charter, dan penerbangan terjadwal. Terlepas dari prioritas atau lokasi Anda, kami selalu dapat diandalkan untuk menemukan solusi yang sesuai dengan kebutuhan Anda dengan cepat"/>
            <ServiceContent
            img="./ship-icon.png"
            heading="Sea Freight"
            content="Kami bekerja sama dengan beberapa perusahaan pelayaran internasional terbesar dan penyedia layanan khusus untuk menawarkan solusi kargo laut yang hemat biaya dan andal. Dengan mencari rute terbaik dan waktu transit sesingkat mungkin, kami akan mengangkut barang dengan aman dan efisien dalam berbagai ukuran, mulai dari palet tunggal hingga muatan kontainer penuh. Kami dapat mengakomodasi ukuran barang Anda dengan jenis kontainer apa pun, mulai dari kontainer 20ft, kontainer 40ft, kontainer High Cube, Flats, dan Open Tops."/>
          <ServiceContent
          img="./warehouse-icon.png"
            heading="Custom Brokerage"
            content="Layanan perantara bea cukai kami bertanggung jawab untuk memastikan pengiriman melewati formalitas ekspor dan impor sehingga mencapai tujuan. Dengan memiliki lisensi pialang bea cukai dan mempekerjakan spesialis bea cukai, kami selalu dapat mengikuti semua peraturan ekspor-impor yang relevan serta prosedur bea cukai yang diperbarui."/>
        </div>
       </div>
    </div>
  )
}

export default Services


  /* 
*/