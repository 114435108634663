import MapComponent from '../components/Contact/ContactUs'
import React from 'react'

function Contact() {
  return(
    <>
     <MapComponent />
    </>
    )
}

export default Contact