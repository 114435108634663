import React from 'react';
import './Achievement.css';
import { useSpring, animated } from 'react-spring';

function Achievement ({ n }){
  const {number} = useSpring({
    from: {number: 0},
    number: n,
    delay: 200,
    config: {mass: 1, tendion: 20, friction: 10 },
  });
  return <div className="tes"><animated.div>{number.to((n) => n.toFixed(0))}</animated.div> </div>
  
}

export default Achievement