import React from 'react'
import "./Footer.css"


const Footer = () => {
  return (
   <div className="footer-contain">
        <footer className="footer-section">
        <div className="container">
           
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                  <a href="index.html"><img src="LOGO - Antar Benua Logistik-01.png" class="img-fluid" alt="logo"/></a>
                            </div>
                            <div className="footer-text">
                                <p>Jl. Tanjung Pura No.5
RT.03 / RW.02
Kel. Pegadungan - Kec. Kalideres 
Jakarta Barat 11830
+6282386837585</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">about</a></li>
                                <li><a href="#">services</a></li>
                                <li><a href="#">portfolio</a></li>
                                <li><a href="#">Contact</a></li>
                                <li><a href="#">About us</a></li>
                                <li><a href="#">Our Services</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2023, All Right Reserved</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                    </div>
                </div>
            </div>
        </div>
    </footer>
   </div>
  )
}

export default Footer

