import React from 'react';
import './Answer.css';
import {useState} from 'react';
import MoreButton from '../MoreButton/MoreButton'
import { Link } from 'react-router-dom'

function Answer(){
  const [selected, setSelected] = useState(null)
  
  const toggle=(i) => {
    if(selected === i){
      return setSelected(null)
    }
    setSelected(i)
  }
  return <div className="wrapper">
      <div className="accordion">
      <h1>Pertanyaan yang sering diajukan</h1>
        {data.map((item, i) =>(
          <div className="item">
            <div className="title" onClick={() => toggle(i)}>
              <h2>{item.question}</h2>
              <span>{selected == i ? '-' : '+'}</span>
            </div>
            <div className={selected == i ? 'content show' : 'content'}>
              {item.answer}
             </div>
          </div>
        ))}
        <Link to="/Contact">
        <MoreButton MoreButton text="HUBUNGI KAMI" type="yellow" />
          </Link>
      </div>
  </div>
}

const data = [
  {
    question: 'Bagaimana cara menggunakan jasa import PT Antar Benua Logistik?',
    answer: 'Caranya mudah. Cukup hubungi Layanan Pelanggan atau tim Penjualan kami untuk arahan lebih lanjut. Antar Benua Logistik akan membantu proses pembayaran ke pemasok, pengiriman barang, pengurusan pajak dan bea cukai, hingga pengiriman ke alamat Anda.'
  },
  {
    question: 'Berapa lama proses pengiriman barang di Antar Benua Logistik?',
    answer: 'Waktu pengiriman barang import China Anda, kurang lebih 45 hari untuk pengiriman jalur Laut, dan sekitar 10 hari untuk pengiriman lewat Udara.'
  },
  {
    question: 'Kapan waktu operasional Layanan Pelangan Antar Benua Logistik?',
    answer: 'Layanan pelanggan kami beroperasi pada jam 08.00-22.00 di hari senin-Jumat dan pukul 08.00-17.00 di hari Sabtu.'
  },
  {
    question: 'Bagaimana jika saya ingin konsultasi, dan lainnya?',
    answer: 'Tim kami siap memberikan solusi dan konsultasi gratis kepada semua customer melalui Whatsapp +6282386837585'
  },
  
  ]
  
  export default Answer;