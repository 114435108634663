import React from 'react';
import Slider from 'react-slick';
import './BlogList.css'
import blogs from './DataBlog';
import "slick-carousel/slick/slick-theme.css";
import Highlight from '../Highlight/Highlight'
import { Link } from 'react-router-dom'

const BlogList = () => {
  const settings = {
    // Konfigurasi Carousel, sesuaikan sesuai kebutuhan
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 500,
        settings: {dots: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
          
        },
      },
    ],
  };

  return (
    <div className="blog-list">
    <img className="banner-blog" src="./container.jpeg"/>
      <Highlight info="Blog" type="lite"/>
      <Slider {...settings}>
        {blogs.map((blog) => (
          <div key={blog.id} className="blog-item">
          <img src={blog.imageUrl}/>
            <h3>{blog.title}</h3>
            <p>{blog.content}</p>
            <Link to={`/${blog.id}`}>
            <a href={`/${blog.id}`}>Baca Selengkapnya</a>
          </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BlogList;
