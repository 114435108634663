import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css';
import Home from './Pages/Home'
import Navbar from './components/Header/Header'
import Service from'./Pages/Service'
import FloatingActionButton from './components/Floating/Floating'
import Footer from './components/Footer/Footer'
import Contact from './Pages/Contact'
import Blog from './Pages/Blog'
import Blog1 from './components/Blog/Blog1'
import Blog2 from './components/Blog/Blog2'
function App() {
  return (
   <>
   <Router>
   <Navbar />
    <Switch>
      <Route path='/' exact component = {Home} />
      <Route path='/service' exact component = {Service} />
      <Route path='/Contact' exact component = {Contact} />
      <Route path='/blog' exact component = {Blog} />
      <Route path='/logistictren' exact component = {Blog1} />
      <Route path='/Menghitungkubikasi' exact component = {Blog2} />
    </Switch>
    <FloatingActionButton />
    <Footer />
   </Router>
   </>
  );
}

export default App;
