import React from 'react';
import '../Blog/BlogPost/ComponentBlog.css'
import ComponentBlog from './BlogPost/ComponentBlog'

function Blog2() {
  return (
    <div className="Appblog">
    <h1 className="pagetitle">Blog</h1>
      <ComponentBlog
        title="Cara Menghitung Kubikasi Agar Import Makin Mudah"
        date=" 30 Okt 2023"
        content={
          <div>
          <p>
          sebagai seorang importir, pernahkan Anda mencari tahu cara menghitung kubikasi pengiriman barang sendiri? Dalam bisnis ekspedisi seperti jasa impor barang dari China ke Indonesia, sudah pasti akan sering menggunakan istilah kubikasi dalam perhitungan biayanya.

Sayangnya, masih banyak pengguna jasa cargo yang menganggap kalau pengiriman barang akan diperhitungkan dari berat barang kiriman saja. Padahal, perhitungannya lebih dari itu, di mana di dunia ekspedisi, masalah kubikasi adalah salah satu hal terpenting yang mesti dihitung.

Namun, pernahkan Anda mencari tahu secara lengkap cara menghitung kubikasi ini? Kalau belum mengetahuinya, yuk simak artikel kali ini yang membahas cara menghitung kubikasi barang impor secara rinci.
          </p>
          <h4>Apa Itu Kubikasi?</h4>
          <p>Kubikasi adalah metode khusus yang biasanya dipakai oleh jasa ekspedisi untuk menghitung volume kargo. Cara menghitung kubikasi sendiri sangat penting untuk diketahui oleh perusahaan cargo dan pengguna (shipper) untuk menghitung biaya pengiriman yang akan dikenakan.</p>
          <p>Khusus bagi importir, pengetahuan cara menghitung kubikasi dan volume pengiriman sendiri dibutuhkan dalam perhitungan Harga Pokok Penjualan (HPP). Dengan mengetahui biaya kirim secara lengkap beserta biaya modal lain, tentu setiap pengguna jasa import bisa memperkirakan keuntungan yang bisa ia dapat dengan pasaran yang ada.</p>
          <h4>Kubikasi dalam Import</h4>
          <p>Sebenarnya, di dunia logistik ada beberapa metode perhitungan biaya kirim. Tentu yang paling umum adalah menghitung biaya pengiriman berdasarkan satuan gram atau kilogram. Namun ada juga pengiriman barang yang biayanya dihitung dengan menggunakan kubikasi. Cara ini digunakan karena terkadang ada barang yang bobotnya ringan tetapi punya ukuran besar.</p>
          <p>Perhitungan dengan metode kubikasi juga dipakai untuk mengirim barang impor dari luar negeri yang jaraknya jauh, terutama dalam jumlah banyak. Khusus untuk pengiriman jenis ini, banyak orang menggunakan pengiriman dengan metode LCL (Less Container Load). Biaya pengiriman LCL dihitung secara CBM atau freight ton.

CBM atau Cubic Meter sendiri merupakan suatu jenis satuan yang biasa digunakan perusahaan cargo untuk menghitung volume barang kiriman. Sedangkan metric ton ialah suatu pengukuran berat kargo, yang bila dirinci 1 MT setara 1000 kg. Penghitungan biaya pengiriman dengan metode CBM juga disebut sebagai w/m atau weight measurement untuk menentukan apakah pengiriman cargo dihitung memakai satuan berat atau volumenya.

Lalu, bagaimana cara menghitung kubikasi CBM? Akan kita bahas di bawah ini.</p>
<h4>Menghitung Kubikasi Cargo</h4>
          <p>Cara menghitung kubikasi cargo sendiri sangat mudah. Cukup dengan mengalikan panjang x lebar x tinggi  barang kiriman. Misalnya, Anda mengirim barang dengan ukuran 5 x 3 x 2, maka kubikasnya 30 CBM.</p>
          <p>Setelah mendapatkan hasilnya, perusahaan cargo akan langsung menyesuaikan biaya pengiriman dengan tipe pengiriman yang dipilih user, yaitu LCL laut, FCL laut, atau LCL udara. Masing-masing dari tipe pengiriman itu mempunyai biaya kiriman yang berbeda-beda.</p>
          <h4>Perhitungan Cargo Laut</h4>
          <p>Untuk informasi, berat volume tidak sama dengan berat barang yang sebenarnya. Contohnya, Anda akan mengirim barang yang punya berat asli 55 kg dengan dimensi 60 x 60 x 60 cm3. Untuk satuan kargo laut adalah 4.000. Maka kalau dihitung, berat volume barang tersebut adalah (60 x 60 x 60)/4.000 = 54 kg volume</p>
          <p>Dengan begitu, berat asli barang di atas berat volume, tetapi yang digunakan untuk perhitungan ongkosnya adalah berat volume. Sedangkan untuk menghitung CBM menggunakan satuan 1.000.000. Contohnya, Anda mengirim barang berukuran 200 x 100 x 100 cm3 maka perhitungan kubikasinya adalah (200 x 100 x 100)/1.000.000 = 2 M3.</p>
          <h4>Perhitungan Kargo Udara</h4>
          <p>Jika ingin menghitung perkiraan pengiriman menggunakan kargo udara, ukuran kubikasi biasanya hanya dipakai jika barang kiriman memiliki ukuran dan jumlah yang besar. Untuk satuan yang digunakan dalam perhitungannya sendiri berbeda dengan pengiriman laut.

Di samping itu, secara biaya, harga </p>
          <p>Di samping itu, secara biaya, harga layanan kargo udara juga lebih mahal. Meskipun begitu, waktu pengiriman akan jadi lebih cepat dibandingkan cargo laut. Untuk menghitung volume kargo udara, satuannya ialah 6.000. Contohnya, Anda mengirim barang berukuran 90 x 90 x 90 cm3 dan berat asli 150 kg maka berat volumenya adalah (90 x 90 x 90)/6.000 = 121,5 kg volume. Perhitungan ini akan dipakai untuk perhitungan biaya kirim walaupun berat asli barang jauh di atas itu.</p>
          </div>}
        imageSrc="Premium Photo _ Row of cargo containers trucks parked lot with the sunset sky shipping cargo container logistics.jpeg"
      />
    </div>
  );
}

export default Blog2;
