import React from 'react';
import Content from './Content';
import './main.css';
import Achievement from './Achievement'

function Main(){
  return(
    
  <div className="main-container">
  <h1>Pencapaian</h1>
  <div className="container">
    <div className="section-top">
       <p>kepuasan pelanggan</p>
      <div className="child">
        <Achievement n={99} /><p>%</p>
      </div>
     </div>
    <div className="section-top">
       <p>total kubikasi</p>
      <div className="child">
        <Achievement n={100}/><p>.</p><Achievement n={500}/><p>.</p><Achievement n={738}/><p>+</p>
      </div>
     </div>
    <div className="section-top">
       <p>pelanggan</p>
      <div className="child">
        <Achievement n={999} /><p>+</p>
      </div>
     </div>
    <div className="section-top">
       <p>Total pengiriman</p>
      <div className="child">
        <Achievement n={1000} /><p>+</p>
      </div>
     </div>
    
 </div>
  </div>
  )
}

export default Main;