const blogs = [
  {
    id: 'logistictren',
    title: "Logistics Trends for 2023: The top 5 trends in logistics",
    content: " Industri logistik terus berubah dan beradaptasi dengan pesatnya pertumbuhan teknologi dan...",
    imageUrl: "Source-Shutterstock.jpg", // 
  },
  {
    id: 'Menghitungkubikasi',
    title: "Cara Menghitung Kubikasi Agar Import Makin Mudah",
    content: "masih banyak pengguna jasa cargo yang menganggap kalau pengiriman barang akan diperhitungkan dari berat barang kiriman saja. Padahal, ..",
    imageUrl: "Premium Photo _ Row of cargo containers trucks parked lot with the sunset sky shipping cargo container logistics.jpeg", // 
  },
];

export default blogs;
