import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './Floating.css';

const FloatingActionButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=+6282386837585', '_blank');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Sesuaikan dengan posisi scroll yang Anda inginkan
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`floating-action-button ${isVisible ? 'visible' : ''}`} onClick={handleWhatsAppClick}>
      <FaWhatsapp className="whatsapp-icon" />
    </div>
  );
};

export default FloatingActionButton;
