import React from "react";
import "./Banner.css";
import Highlight from "../Highlight/Highlight";

export default function Banner() {
  
  return (
    <div className="banner">
      <section>
        <Highlight info="PT. Antar Benua Logistik" type="dark" />
        <h1>your gateway to any Destination in the World</h1>
        <p>Tingkatkan Efisiensi Bisnis Anda dengan Solusi Logistik Terkemuka - Antarbenua Logistic Menjadi Mitra Andal Anda!
        </p>
      </section>
    </div>
  );
}
