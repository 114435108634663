import React from 'react';
import './ContentofService.css'
import Highlight from '../Highlight/Highlight'



const Card = ({ title, content, imageUrl }) => (
  <div className="card">
    <div className="card-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
    <h3 className="card-title">{title}</h3>
    <p className="card-content">{content}</p>
  </div>
);

const CardList = () => {
  const cardsData = [
    {
      title: 'Shipping',
      content: 'Menghubungkan Anda dengan berbagai daerah di Indonesia dan belahan dunia lainnya.',
      imageUrl: './project-card-img5.png',
    },
    {
      title: 'Port',
      content: 'Memiliki pengalaman dalam menangani kontainer, bulk cargo, over-dimension & heavy duty cargo.',
      imageUrl: './container.jpeg',
    },
    {
      title: 'Logistic',
      content: 'Menawarkan solusi transportasi dan logistik terintegrasi untuk menjawab kebutuhan Anda.',
      imageUrl: './project-card-img3.png',
    },
    {
      title: 'Service',
      content: 'Memberikan nilai lebih bagi pelanggan dan masyarakat dengan pemahaman kami tentang medan di daerah lokal.',
      imageUrl: './blog-img2.png',
    },
    {
      title: 'Shipyard',
      content: 'Mampu memberikan jaminan kualitas, harga, Pengiriman dan keselamatan',
      imageUrl: './blog-img1.png',
    },
    {
      title: 'Card 6',
      content: 'Memberikan nilai lebih bagi pelanggan dan masyarakat dengan pemahaman kami tentang medan di daerah lokal.',
      imageUrl: './blog-img3.png',
    },
  ];

  return (
    <div className="card-list">
      {cardsData.map((card, index) => (
        <Card key={index} title={card.title} content={card.content} imageUrl={card.imageUrl} />
      ))}
    </div>
  );
};

export default CardList;
