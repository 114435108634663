import "./PageService.css"
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import ContentPageService from './Contentofservice'
import CardList from './Contentofservice'


class PageService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPart: 1, 
    };
  }

  handleBlockClick = (part) => {
    this.setState({ currentPart: part });
  }

  render() {
    const { currentPart } = this.state;

    return (
<>
    <div className="maincontainer-service">
  <h1>Layanan Kami</h1>
  <div className="container-service">
    <div className="block-buttons">
      <button onClick={() => this.handleBlockClick(1)}>LCL by Sea</button>
      <button onClick={() => this.handleBlockClick(2)}>LCL by Air</button>
      <button onClick={() => this.handleBlockClick(3)}>FCL</button>
    </div>
    <div className="text-container">
      <CSSTransition
        in={currentPart === 1}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div className="parent">
          <img src="blog-img3.png" />
          <div>Less Container Load by Sea adalah pengiriman barang menggunakan kapal kargo untuk kuantitas dan ukuran kecil di dalam satu container bersama pengiriman lainnya. Keuntungan dari LCL adalah biaya pengiriman import barang dari China ke Indonesia akan jauh lebih murah, mulai dari sekitar 4 juta/CBM dengan minimum pengiriman 0.1CBM per sekali kirim. Kami akan menjamin pengiriman barang Anda selalu dalam keadaan aman. Jika terjadi kehilangan, kami akan menggantinya sesuai dengan jumlah nilai barang.</div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={currentPart === 2}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div className="parent">
          <img src="Lufthansa's cargo arm records another ebit loss - Air Cargo News.jpeg"  />
          <div>Less Container Load by Air adalah pengiriman barang melalui jalur udara atau menggunakan pesawat. Kelebihan dari pengiriman import barang dari China ke Indonesia ini adalah estimasi waktu yang lebih cepat hanya 5-7 Hari. Biaya tambahan akan dikenakan untuk produk yang mengandung Baterai dan Bermerk (Branded). Pengiriman LCL by Air dapat dilakukan dengan minimum berat 3Kg untuk sekali pengiriman.</div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={currentPart === 3}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div className="parent">
          <img src="Premium Photo _ Row of cargo containers trucks parked lot with the sunset sky shipping cargo container logistics.jpeg" />
          <div>Full Container Load adalah pengiriman barang dalam satu kontainer penuh sehingga import barang dari China Anda dapat dikirim tanpa tercampur dengan milik orang lain. Anda juga dapat menggunakan jasa FCL untuk pengiriman barang dalam jumlah besar di satu kontainer. Satu kontainer dapat menampung barang mulai 78 CBM hingga 80 CBM. Pengiriman barang FCL ini hanya bisa melalui jalur laut/kapal kargo dengan estimasi pengiriman sekitar 3-4 Minggu.</div>
        </div>
      </CSSTransition>
    </div>
  </div>
</div>

<CardList />
</>

    );
  }
}

export default PageService;


            
            