import React from 'react';
import './ComponentBlog.css'
const ComponentBlog = ({ title, date, content, imageSrc }) => {
  return (
    <div className="blog-post">
    <div className="blog-post-parent">
      <img src={imageSrc} alt="Blog Image" />
    </div>
      <h2 className="title-blog">{title}</h2>
      <p className="date">Tanggal: {date}</p>
      <p className="content-blog">{content}</p>
    </div>
  );
}

export default ComponentBlog;
