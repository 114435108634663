import React from 'react';
import '../App.css'
import Main from '../components/Achievement/Mainchivmaent';
import Question from '../components/Question/Question'
import SliderComponent from '../components/Slider/Slider'
import Services from "../components/Services/Services"
import Banner from '../components/Banner/Banner'
import BlogList from '../components/BlogList/BlogList'
import Vision from '../components/Vision/Vidion'

function Home (){
  return(
    <div>
      <Banner />
      <Main />
      <Question />
      <Vision />
      <SliderComponent />
      <Services />
      <BlogList/>
    </div>
    )
}

export default Home